export interface iAuthInfo {
  email: string;
  firstName: string;
  lastName: string;
}

export function isRecord(obj: unknown): obj is Record<string, unknown> {
  return (
    typeof obj === "object" &&
    obj !== null &&
    !Array.isArray(obj) &&
    !(obj instanceof Date)
  );
}

export function getAuthInfo(props: unknown): iAuthInfo | undefined {
  if (
    props &&
    isRecord(props) &&
    "authInfo" in props &&
    props["authInfo"] &&
    isRecord(props["authInfo"]) &&
    "email" in props["authInfo"] &&
    "firstName" in props["authInfo"] &&
    "lastName" in props["authInfo"] &&
    typeof props["authInfo"]["email"] === "string" &&
    typeof props["authInfo"]["firstName"] === "string" &&
    typeof props["authInfo"]["lastName"] === "string"
  ) {
    return {
      email: props["authInfo"]["email"],
      firstName: props["authInfo"]["firstName"],
      lastName: props["authInfo"]["lastName"],
    };
  }

  return undefined;
}
