import { iNonprofitPrivate } from "@givsly/aws-tenant-manager/lib/types";
import { FormApi } from "final-form";
import React, { FC, useCallback, useState } from "react";
import { FormRenderProps, Form as FinalForm } from "react-final-form";
import css from "../TenantManagerPage.module.css";
import { updateNonprofitPrivateData } from "../api";
import { SubmitButton } from "../shared/SubmitButton";
import { FormTextField } from "../shared/FormTextField";
import { FormCheckboxField } from "../shared/FormCheckboxField";

interface iNonprofitPrivateFormFields {
  prefix?: string;
}

export const NonprofitPrivateFormFields: FC<iNonprofitPrivateFormFields> = ({
  prefix = "",
}) => {
  return (
    <div className={css["stack"]}>
      <FormTextField
        name={`${prefix}stripe_id`}
        label="Stripe connected account ID (readonly)"
        st
        readOnly
      />
      <FormCheckboxField
        name={`${prefix}emails_partnerships`}
        label="Email partnership notifications"
        hint="If enabled, emails are sent to partnership approval -address"
      />
    </div>
  );
};

interface iNonprofitPrivateForm {
  npoId: string;
  listingId: string;
  privateData: Partial<iNonprofitPrivate>;
  onAfterSubmit?: () => void;
}

export const NonprofitPrivateForm: FC<iNonprofitPrivateForm> = ({
  npoId,
  privateData,
  onAfterSubmit,
  listingId,
}) => {
  const [error, setError] = useState<string>();
  const onSubmit = useCallback(
    async (
      values: iNonprofitPrivate,
      form: FormApi<iNonprofitPrivate, Partial<iNonprofitPrivate>>
    ) => {
      const result = await updateNonprofitPrivateData(npoId, listingId, values);
      console.log(result);
      if (result.status >= 400) {
        setError(`[${result.json.code}]: ${result.json.message}`);
      }
      if (typeof onAfterSubmit === "function") {
        onAfterSubmit();
      }

      form.setConfig("initialValues", values);
      setTimeout(() => {
        form.reset();
      }, 1000);
    },
    [listingId, npoId, onAfterSubmit]
  );

  return (
    <>
      {error && <div className={css["error"]}>{error}</div>}
      <FinalForm
        onSubmit={onSubmit}
        initialValues={privateData}
        render={(formRenderProps: FormRenderProps<iNonprofitPrivate>) => {
          const { handleSubmit, submitting, submitSucceeded } = formRenderProps;
          return (
            <form onSubmit={handleSubmit}>
              <NonprofitPrivateFormFields />
              <SubmitButton
                label="Update private data"
                submitting={submitting}
                submitSucceeded={!error && submitSucceeded}
              />
            </form>
          );
        }}
      />
    </>
  );
};
