import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import {
  addUserToGroup,
  removeTenantUser,
  removeUserFromGroup,
  verifyUserEmail,
} from "../api";
import { NonprofitUserForm } from "./NonprofitUserForm";
import Table from "../shared/Table";
import { GROUPS, iUser } from "@givsly/aws-tenant-manager";
import { AsyncCheckbox, checkboxStyle } from "../shared/AsyncCheckbox";

interface iUserRow {
  user: iUser;
  setData: Dispatch<SetStateAction<iUser[] | undefined>>;
  isGA?: boolean;
  isGM?: boolean;
}

const UserRow: FC<iUserRow> = ({
  user,
  setData,
  isGA = false,
  isGM = false,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const {
    email,
    name,
    status,
    username,
    groups = [],
    tenant_id,
    email_verified,
    sharetribe_id,
  } = user;
  const updateUserGroup = useCallback(
    async (checked: boolean, group: GROUPS) => {
      setIsUpdating(true);
      const result = checked
        ? await removeUserFromGroup({ username, email, group, tenant_id })
        : await addUserToGroup({ username, email, group, tenant_id });

      if (result === 200) {
        const new_groups = checked
          ? groups.filter((_group) => _group !== group)
          : [...groups, group];
        setData((_users) => {
          if (_users && Array.isArray(_users)) {
            return _users.map((_user) =>
              _user.username === username
                ? { ..._user, groups: new_groups }
                : _user
            );
          }
          return _users;
        });
      }
      setIsUpdating(false);
    },
    [email, groups, setData, tenant_id, username]
  );

  const handeUserEmailVerification = useCallback(async () => {
    setIsUpdating(true);
    const result = await verifyUserEmail(username);

    if (result === 200) {
      setData((_users) => {
        if (_users && Array.isArray(_users)) {
          return _users.map((_user) =>
            _user.username === username
              ? { ..._user, email_verified: true }
              : _user
          );
        }
        return _users;
      });
    }
    setIsUpdating(false);
  }, [setData, username]);

  const removeUser = useCallback(async () => {
    setIsUpdating(true);
    const result = await removeTenantUser(username, email);
    if (result === 200) {
      setData((users = []) =>
        users.filter((_user) => _user.username !== username)
      );
    }
    setIsUpdating(false);
  }, [email, setData, username]);

  return (
    <tr key={email}>
      <td>{name}</td>
      <td>{email}</td>
      <td>{status}</td>
      <td>
        <AsyncCheckbox
          checked={groups.indexOf(GROUPS.ADMINS) > -1}
          onChange={async (checked) => updateUserGroup(checked, GROUPS.ADMINS)}
        />
      </td>
      <td>
        <input
          type="checkbox"
          checked={
            typeof sharetribe_id === "string" && sharetribe_id.length > 0
          }
          readOnly
          disabled
          style={checkboxStyle}
        />
      </td>
      <td>
        <AsyncCheckbox
          forwardRef={{ disabled: email_verified || isUpdating }}
          checked={email_verified}
          onChange={async (checked) => handeUserEmailVerification()}
        />
      </td>
      <td>
        <button type="button" onClick={removeUser} disabled={isUpdating}>
          X
        </button>
      </td>
    </tr>
  );
};

interface iNonprofitUsers {
  npo_id: string;
  error?: Error;
  isLoading?: boolean;
  data?: iUser[];
  retry: () => void;
  setData: Dispatch<SetStateAction<iUser[] | undefined>>;
}

const TABLE_HEADERS = [
  "Name",
  "Email",
  "Status",
  "Admin",
  "ST",
  "Email verified",
  "Actions",
];

export const NonprofitUsers: FC<iNonprofitUsers> = ({
  npo_id,
  error,
  data = [],
  isLoading,
  retry,
  setData,
}) => {
  if (error) {
    return (
      <p>
        Error: {error}{" "}
        <button type="button" onClick={() => retry()}>
          refresh
        </button>
      </p>
    );
  }

  return (
    <>
      <h2>Users</h2>
      <Table loading={isLoading} headers={TABLE_HEADERS}>
        {data.map((user) => (
          <UserRow key={user.email} user={user} setData={setData} />
        ))}
      </Table>
      <h2>Create users</h2>
      <NonprofitUserForm
        npo_id={npo_id}
        onAfterSubmit={() => {
          retry();
        }}
      />
    </>
  );
};
