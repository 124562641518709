import { FormApi, ValidationErrors } from "final-form";
import React, { FC, useCallback, useEffect, useState } from "react";
import {
  AnyObject,
  Form as FinalForm,
  useForm,
  useFormState,
} from "react-final-form";
import { TextField } from "../../../components";
import css from "../TenantManagerPage.module.css";
import { ui_createNonprofit, searchDafs } from "../api";
import { useAuthInfo } from "../shared/AuthInfoProvider";
import { SubmitButton } from "../shared/SubmitButton";
import { FormCheckboxField } from "../shared/FormCheckboxField";
import { createContact, iNonprofitCreate, iDAFNonprofit } from "@givsly/aws-tenant-manager/lib/types";
import { useAsyncCallback } from "../shared/useAsyncCallback";
import { FormSelectField } from "../shared/FormSelectField";
 
interface iNonprofitForm {
  onAfterSubmit?: () => void;
}

function extractDomain(address: string): string {
  address = address.trim().toLowerCase();

  if (!address) {
    return "";
  }

  if (!address.includes('://')) {
    address = `http://${address}`;
  }

  try {
    const parsedURL = new URL(address);
    const hostname = parsedURL.hostname;

    // Regular expression to extract the main domain
    const re = /(?:\w+\.)?(\w+\.\w+)$/;
    const matches = hostname.match(re);

    return matches && matches.length > 1 ? matches[1] : hostname;
  } catch (err) {
    console.error('Error parsing URL:', err);
    return "";
  }
}


const DafSelect: FC = () => {
  const form = useForm();
  const formState = useFormState({
    subscription: { values: true },
  });

  const tax_id = formState.values?.tax_id?.trim();
  const country = formState.values?.country?.trim();

  const searchDafsFunction = useCallback(() => {
    return searchDafs({ tax_id, country });
  }, [tax_id, country]);

  const { data, retry } = useAsyncCallback(searchDafsFunction);

  useEffect(() => {
    if (tax_id && country) {
      retry();
    }
  }, [tax_id, country, retry]);

  // Define options based on the number of nonprofits
  let options = [];
  if (data && data?.nonprofits?.length > 0) {
    options = [
      { value: "", label: `Choose a nonprofit - found ${data.nonprofits.length}` },
      
      ...data.nonprofits.map(npo => ({
        value: npo.daf_id,
        label: npo.name || npo.website || npo.daf_id,
      })),
    ];
  } else {
    if (tax_id) {
      options = [{ value: "", label: `Nonprofit with EIN ${tax_id} not found` }];
    } else {
      options = [{ value: "", label: `Start by entering an EIN to start searching...` }];
    }
  }

  return (
    <FormSelectField
      disabled={data?.nonprofits?.length === 0}
      name="daf_id"
      label="Choose the correct nonprofit"
      options={options}
      onChange={(value) => {
        console.log(`SELECTED ${value}`)
        const daf_response = data?.nonprofits?.find((npo: iDAFNonprofit) => npo.daf_id === value);

        if (!daf_response) {
          return
        }

        const domain = extractDomain(daf_response.website);

        // Populate the FORM fields with the NPO selection
        form.change("npo_id", domain); 
        form.change("npo_name", daf_response.name); 
        form.change("category", daf_response.category); 
        form.change("website", daf_response.website);
        form.change("admin_email", daf_response.email); 
        form.change("daf_response", daf_response);
      }}
    />
  );
};

export const NonprofitForm: FC<iNonprofitForm> = ({ onAfterSubmit }) => {
  const authInfo = useAuthInfo();
  const [error, setError] = useState<string>();

  function sanitizeString(input: string): string {
    return input.replace(/[^a-zA-Z0-9]/g, '');
}

  const onSubmit = useCallback(
    async (values: AnyObject, form: FormApi<AnyObject, Partial<AnyObject>>) => {
      setError(undefined);
      const npo_id = values?.npo_id?.trim();
      const npo_name = values?.npo_name?.trim();
      const listing_id = values?.listing_id?.trim();
      const admin_name = values?.admin_name?.trim();
      const admin_email = values?.admin_email?.trim();
      const country = values?.country?.trim();
      const dafNpo = values?.daf_response as iDAFNonprofit;
      const person_admin = createContact(admin_email, admin_name)
      const tax_id =  values?.daf_response?.tax_id?.trim() || ""
      const tax_type = sanitizeString(values?.tax_type)

      if (!tax_id) {
        setError(`A tax ID like a EIN is required`);
        return;
      }

      //  501(c)(3)
      if (!tax_type || tax_type !== "501c3") {
        setError(`Only 501c3 nonprofits are supported`);
        return;
      }

      const payload: iNonprofitCreate = {
        npo_id,
        tax_id,
        tax_type,
        listing_id,
        country,
        daf_response: values?.daf_response,
        public: {
          name: npo_name,
          category: dafNpo.category,
          ntee_codes: dafNpo.ntee_codes,
          mission: dafNpo.mission,
          socials: {
            website: dafNpo.website,
            facebook: dafNpo.facebook,
            instagram: dafNpo.instagram,
            twitter: dafNpo.twitter
          },
          person_admin: person_admin
        },
        private: {},
        creator: {
          name: authInfo ? `${authInfo.firstName} ${authInfo.lastName}` : "",
          email: authInfo?.email || "",
          first_name: authInfo?.firstName || "",
          last_name: authInfo?.lastName || "",
        },
      };

      try {
        const result = await ui_createNonprofit(
          payload,
          values.create_admin_from_owner
        );

        if (result.status >= 400) {
          if (result.json.message) {
            setError(`[${result.status} - ${result.json.code}]: ${result.json.message}`);
          } else if (result.json.error) {
            setError(`[${result.status}]: ${result.json.error}`);
          } 
          return;
        }
      } catch (error) {
        // Handle errors like `TypeError: Failed to fetch` that occur during fetching
        console.error("Submission failed", error);
        setError(`[network error]: ${error}`);
        return;
      }

      if (typeof onAfterSubmit === "function") {
        onAfterSubmit();
      }

      setTimeout(() => {
        form.reset();
      }, 1000);
    },
    [authInfo, onAfterSubmit]
  );

  return (
    <FinalForm
      onSubmit={onSubmit}
      initialValues={{}}
      validate={(values: AnyObject) => {
        const errors: ValidationErrors = {
          creator: {},
          public: {},
        };

        const domainRegex =
          /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
        if (values.npo_id && !domainRegex.test(values.npo_id)) {
          errors.npo_id = "Must be a valid domain name";
        }

        if (!values.country) {
          errors.country = "Country is required";
        }
        const countryRegex = /^[A-Z]{2}$/;
        if (values.country && !countryRegex.test(values.country)) {
          errors.country = "Must be a valid ISO 3166-1 alpha-2 value";
        }

        return errors;
      }}
      render={(formRenderProps) => {
        const { handleSubmit, submitting, submitSucceeded } = formRenderProps;
        return (
          <>
            {error && <div className={css["error"]}>{error}</div>}
            <form onSubmit={handleSubmit}>

              <div className={[css.row, css.grow].join(" ")}>
                <TextField
                  readOnly
                  name="country"
                  type="text"
                  id="country"
                  label="Country (ISO 3166-1 alpha-2)"
                  defaultValue="US"
                />

                <TextField
                  name="tax_id" 
                  type="text" 
                  id="tax_id" 
                  label="EIN" 
                />

                <TextField
                  name="tax_type" 
                  type="text" 
                  id="tax_type" 
                  label="Nonprofit Type" 
                />
              </div>

              <DafSelect />

              <div 
              style={{
                marginTop: 64,
                marginBottom: 64,
                border: "1px solid #dadada",
                padding: 32,
                borderRadius: 4,
              }}
              >

                <div style={{marginTop: 30}}></div>

                  <div className={[css.row, css.grow].join(" ")}>
                    <TextField
                      disabled
                      required
                      name="npo_name"
                      type="text"
                      id="npo_name"
                      label="Name (Cannot edit as it comes from the DAF)"
                    />

                    <TextField
                      disabled
                      required
                      name="category"
                      type="text"
                      id="category"
                      label="Category (Cannot edit as it comes from the DAF)"
                    />

                  </div>

                  <div className={[css.row, css.grow].join(" ")}>

                    <TextField
                      required
                      name="website"
                      type="text"
                      id="website"
                      label="Website (https://cancer.org)"
                    />

                    <TextField
                      required
                      name="npo_id"
                      type="text"
                      id="npo_id"
                      label="Nonprofit ID - Domain (e.g. cancer.org)"
                    />
                  </div>

                  <div className={[css.row, css.grow].join(" ")}>

                    <TextField
                      required
                      type="text"
                      name="admin_name"
                      id="admin_name"
                      label="Admin Name"
                    />

                    <TextField
                      required
                      type="email"
                      name="admin_email"
                      id="admin_email"
                      label="Admin Email"
                    />

                  </div>

                  <div className={[css.row, css.grow].join(" ")}>

                    <TextField
                      name="listing_id"
                      type="text"
                      id="listing_id"
                      label="Sharetribe ID (required)"
                    />

                  </div>

              </div>

              

              {formRenderProps.values?.listing_id && (
                <FormCheckboxField
                  name="create_admin_from_owner"
                  label="Make listing owner a admin user"
                />
              )}

              <SubmitButton
                label="Create nonprofit"
                submitSucceeded={submitSucceeded && !error}
                submitting={submitting}
              />
            </form>
          </>
        );
      }}
    />
  );
};
