import React, { FC, useCallback } from "react";
import { getNonprofit, getNonprofitUsers } from "../api";
import { useAsyncCallback } from "../shared/useAsyncCallback";
import { useRouteMatch } from "react-router";
import { TenantAdminForm } from "../subscribers/TenantAdminForm";
import { NonprofitUsers } from "./NonprofitUsers";
import { NonprofitPrivateForm } from "./NonprofitPrivateForm";

interface iNpoDetail {
  npoId: string;
}

export const NonprofitDetail: FC<iNpoDetail> = ({ npoId }) => {
  const match = useRouteMatch<any>();
  const section = match.params.section;
  const id = match.params.id;

  const _fetchTenant = useCallback(() => getNonprofit(npoId), [npoId]);
  const tenant = useAsyncCallback(_fetchTenant);

  const _fetchNpoUsers = useCallback(() => getNonprofitUsers(npoId), [npoId]);
  const users = useAsyncCallback(_fetchNpoUsers);

  if (tenant.isLoading) return <p>Loading info for {npoId} ...</p>;

  if (tenant.isError || !tenant.data)
    return (
      <p>
        Something went wrong.{" "}
        <button type="button" onClick={() => tenant.retry()}>
          refresh
        </button>
      </p>
    );

  if (match.path.includes("edit") && section && id) {
    if (section === "admin") {
      return <TenantAdminForm />;
    }
  }

  const name = tenant.data.public?.name;

  return (
    <div>
      <h1>
        {name} ({npoId})
      </h1>

      <NonprofitUsers npo_id={npoId} {...users} />
      
      <h2>Nonprofit Private Fields</h2>
      <NonprofitPrivateForm
        npoId={npoId}
        listingId={tenant.data.listing_id}
        privateData={tenant.data?.private}
      />
    </div>
  );
};
