import React, { FC } from "react";
import { Field } from "react-final-form";
import { Badge } from "./Badge";

interface iFormTextField {
  name: string;
  label: string;
  type?: string;
  st?: boolean;
  hint?: string;
  showValuesAsImage?: boolean;
  readOnly?: boolean;
}
export const FormTextField: FC<iFormTextField> = ({
  name,
  label,
  type = "text",
  st = false,
  hint,
  showValuesAsImage = false,
  readOnly = false,
}) => (
  <Field
    name={name}
    type={type}
    render={({ input, meta }) => (
      <label style={{ lineHeight: "16px" }}>
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          {showValuesAsImage && (
            <img
              src={input.value}
              alt=""
              style={{ width: 48, height: 48, marginRight: 8 }}
            />
          )}
          <div>
            {label} {st && <Badge>ST</Badge>}
            {hint && (
              <>
                <br />
                <small>{hint}</small>
              </>
            )}
          </div>
        </div>
        <input {...input} style={{ marginTop: 8 }} readOnly={readOnly} />
        {meta.error && meta.touched && (
          <span style={{ color: "var(--failColor)" }}>{meta.error}</span>
        )}
      </label>
    )}
  />
);
